import { useSyncCSSProperty } from "@kablamooss/geo-ds-core-components";
import useDimensions from "react-cool-dimensions";
import styled, { createGlobalStyle, css } from "styled-components";
import FlexColumnLayout from "../FlexColumnLayout/FlexColumnLayout";

export const APP_HEADER_HEIGHT_PROPERTY = "--app-header-height";

export type AppLayoutVariant = "default" | "weak";

const AppLayoutGlobalStyles = createGlobalStyle`
  /* Scroll padding --------------------------------------------------------- */
  /* When using scrollIntoView this will provide some padding to account for
  the fixed header */
  html {
    scroll-padding-top: calc(var(${APP_HEADER_HEIGHT_PROPERTY}) + 24px);
  }
`;

interface StyledAppLayoutProps {
  variant: AppLayoutVariant;
}

const StyledAppLayout = styled.div<StyledAppLayoutProps>`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${(p) =>
    p.variant === "weak" &&
    css`
      background-color: ${p.theme.colors.neutrals.backgroundWeak};
    `}
`;

export const StyledAppHeader = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: ${(p) => p.theme.zIndexes.appHeader};
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

interface AppLayoutProps {
  children?: React.ReactNode;
  header: React.ReactNode;
  subheader?: React.ReactNode;
  variant?: AppLayoutVariant;
}

const AppLayout = ({
  children,
  header,
  subheader,
  variant = "default",
}: AppLayoutProps) => {
  const { observe: headerRef, height } = useDimensions();

  useSyncCSSProperty({
    name: APP_HEADER_HEIGHT_PROPERTY,
    value: `${height}px`,
  });

  return (
    <>
      <AppLayoutGlobalStyles />
      <StyledAppLayout variant={variant}>
        <StyledContent>
          <StyledAppHeader ref={headerRef}>
            {header}
            {subheader}
          </StyledAppHeader>
          <FlexColumnLayout>{children}</FlexColumnLayout>
        </StyledContent>
      </StyledAppLayout>
    </>
  );
};

export default AppLayout;
