import {
  NavDrawerToggleButton,
  NavHeader,
  useSyncCSSProperty,
} from "@kablamooss/geo-ds-core-components";
import useDimensions from "react-cool-dimensions";
import styled from "styled-components";
import getPublicRuntimeConfig from "../../../utils/getPublicRuntimeConfig/getPublicRuntimeConfig";
import EnvironmentBanner from "../EnvironmentBanner/EnvironmentBanner";
import NavHeaderBackButton from "../NavHeaderBackButton/NavHeaderBackButton";

export const APP_NAV_HEADER_HEIGHT_PROPERTY = "--app-nav-header-height";

const StyledNavHeader = styled(NavHeader)`
  .env-sandbox & {
    background-color: ${(p) => p.theme.colors.warning.textHover};
  }
`;

interface AppNavHeaderProps {
  actions?: React.ReactNode;
  parentHref?: string;
  tabList?: React.ReactNode;
  title: React.ReactNode | string;
}

const AppNavHeader = ({
  actions,
  parentHref,
  tabList,
  title,
}: AppNavHeaderProps) => {
  const { observe: headerRef, height } = useDimensions();

  const { ENVIRONMENT, IGNORE_ENVIRONMENT_INDICATOR } =
    getPublicRuntimeConfig();

  const showEnvironmentBanner = ENVIRONMENT !== "prod";

  useSyncCSSProperty({
    name: APP_NAV_HEADER_HEIGHT_PROPERTY,
    value: `${height}px`,
  });

  const hasBackButton = !!parentHref;

  return (
    <>
      {showEnvironmentBanner && !IGNORE_ENVIRONMENT_INDICATOR && (
        <EnvironmentBanner data-testid="environment-banner" />
      )}
      <StyledNavHeader
        actions={actions}
        nav={
          hasBackButton ? (
            <NavHeaderBackButton parentHref={parentHref} />
          ) : (
            <NavDrawerToggleButton data-testid="nav-drawer-toggle" />
          )
        }
        ref={headerRef}
        tabList={tabList}
        title={title}
      />
    </>
  );
};

export default AppNavHeader;
