import type { AppGeneralPermission } from "../.rest-hooks/types";
import { useGetPermissions } from "../.rest-hooks/user-management";

type PermissionExpression = (
  permissions: Partial<Record<AppGeneralPermission, boolean>>,
) => boolean | undefined;

export interface UseHasPrivilegeParams {
  requestedPermissions: readonly AppGeneralPermission[] | PermissionExpression;
}

export const useHasPrivilege = ({
  requestedPermissions,
}: UseHasPrivilegeParams) => {
  return useGetPermissions({
    query: {
      select: (data) => {
        const { permissions } = data.data.meta;
        return typeof requestedPermissions === "function"
          ? !!requestedPermissions(
              Object.fromEntries(
                permissions.map((permission) => [permission, true]),
              ),
            )
          : requestedPermissions.every((permission) =>
              permissions.includes(permission),
            );
      },
    },
  });
};
