// Deprecated in Node, but this is what Next.JS uses internally for the pages router
import { parse } from "querystring";
import type { UrlObject } from "url";

type Url = string | UrlObject;

export const FAKE_ORIGIN = "http://fake.localhost";

type UrlWithParsedQuery = Omit<UrlObject, "query"> & {
  query: Exclude<UrlObject["query"], string>;
};

export const getToUrlObject = (href: Url): UrlWithParsedQuery => {
  if (typeof href === "object") {
    return {
      ...href,
      query: typeof href.query === "string" ? parse(href.query) : href.query,
    };
  }

  const url = new URL(href, FAKE_ORIGIN);
  if (url.origin !== FAKE_ORIGIN) {
    throw new Error("toUrlObject may only be passed a relative URL");
  }

  return {
    hash: url.hash,
    pathname: url.pathname,
    query: parse(url.search),
  };
};

export const getPathnameFromAsPath = (asPath: string): string => {
  const url = new URL(asPath, FAKE_ORIGIN);
  return url.pathname;
};
