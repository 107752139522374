import {
  Info,
  Text,
  TypographyIconWrapper,
  Warning,
} from "@kablamooss/geo-ds-core-components";
import styled, { css } from "styled-components";
import { getEnvironment } from "../../../config/environment";

const StyledEnvironmentBanner = styled.div<{ isSandboxEnvironment: boolean }>`
  display: flex;
  padding: 0.25rem 1rem;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  z-index: ${(p) => p.theme.zIndexes.appHeader};
  overflow-x: auto;
  white-space: nowrap;

  ${(p) =>
    p.isSandboxEnvironment
      ? css`
          background-color: ${p.theme.colors.warning.backgroundStrong};
          color: ${p.theme.colors.neutrals.background};
        `
      : css`
          background-color: ${p.theme.colors.informative.backgroundWeak};
          color: ${p.theme.colors.informative.text};
        `}
`;

type EnvironmentBannerProps = {
  "data-testid"?: string;
};

const EnvironmentBanner = ({
  "data-testid": dataTestId,
}: EnvironmentBannerProps) => {
  const environment = getEnvironment();

  const tenant =
    environment === "sandbox"
      ? "RMP Sandbox"
      : `${environment.toUpperCase()} environment`;

  const isSandboxEnvironment = environment === "sandbox";

  return (
    <StyledEnvironmentBanner
      data-testid={dataTestId}
      isSandboxEnvironment={isSandboxEnvironment}
    >
      <TypographyIconWrapper
        icon={isSandboxEnvironment ? Warning : Info}
        size="label"
        variant={isSandboxEnvironment ? "inverse" : "info"}
      />
      <Text data-testid={dataTestId && `${dataTestId}-message`} size="label">
        Currently operating in the {tenant}
      </Text>
    </StyledEnvironmentBanner>
  );
};

export default EnvironmentBanner;
