enum QueryParams {
  ACTIVE_ASSET_LAYER = "activeAsset",
  ACTIVE_IGNITIONS_LAYER = "activeIgnition",
  HAS_BACK_STACK = "hasBackStack",
  PROJECT_ID = "projectId",
  RUN_ID = "runId",
  TAB = "tab",
  SECTION = "section",
  TYPE = "type",
  SUBTYPE = "subtype",
}

export default QueryParams;
