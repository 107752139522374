import Link, { type LinkProps } from "next/link";
import { useRouter, type NextRouter } from "next/router";
import type { HTMLAttributeAnchorTarget } from "react";
import { getPathnameFromAsPath } from "../../../utils/router/router";

type IsActiveFn = (router: NextRouter) => boolean;

export interface NavLinkRenderProps {
  isActive: boolean;
}

export type NavLinkProps = LinkProps & {
  exact?: boolean;
  isActive?: IsActiveFn;
  render: (props: NavLinkRenderProps) => React.ReactNode;
  target?: HTMLAttributeAnchorTarget;
};

interface UseIsNavLinkActiveParams {
  exact?: boolean;
  href: LinkProps["href"];
  isActive?: IsActiveFn;
}

interface IsNavLinkActiveParams {
  exact?: boolean;
  href: LinkProps["href"];
  router: NextRouter;
}

export const isNavLinkActive = ({
  href,
  router,
  exact,
}: IsNavLinkActiveParams) => {
  const { asPath } = router;

  // Ensure href is a string
  const hrefString = typeof href === "string" ? href : href.href;

  const processedPathname = getPathnameFromAsPath(asPath);

  // Compare the processed pathname with the hrefString
  if (exact) {
    return processedPathname === hrefString;
  }
  return processedPathname.startsWith(hrefString!);
};

export const useIsNavLinkActive = ({
  exact,
  href,
  isActive,
}: UseIsNavLinkActiveParams) => {
  const router = useRouter();

  if (isActive) {
    return isActive(router);
  }

  return isNavLinkActive({ exact, href, router });
};

const NavLink = ({
  exact,
  isActive: isActiveFn,
  passHref = true,
  render,
  ...props
}: NavLinkProps) => {
  const { href } = props;
  const isActive = useIsNavLinkActive({
    exact,
    href,
    isActive: isActiveFn,
  });

  return (
    <Link passHref={passHref} {...props}>
      {render({ isActive })}
    </Link>
  );
};

export default NavLink;
