import Link, { type LinkProps } from "next/link";
import { useRouter } from "next/router";
import { useCallback } from "react";
import QueryParams from "../../../config/queryParams";

interface BackButtonRenderProps {
  onClick?: React.MouseEventHandler<HTMLElement>;
}

interface BackButtonProps extends LinkProps {
  render: (props: BackButtonRenderProps) => React.ReactNode;
}

const BackButton = ({ render, ...props }: BackButtonProps) => {
  const { back, query } = useRouter();

  const onClick = useCallback(() => {
    back();
  }, [back]);

  if (query[QueryParams.HAS_BACK_STACK]) {
    return <>{render({ onClick })}</>;
  }

  return <Link {...props}>{render({})}</Link>;
};

export default BackButton;
